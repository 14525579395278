<template>
  <div id="app">
    <b-overlay :show="loading" no-wrap></b-overlay>
    <my-header/>
    <div class="container">
      <h1 class="text-center mt-2">Password Generator</h1>

      <b-row class="my-1">
        <b-col sm="2" class="my-2">
          <label for="input-small">Password Count:</label>
        </b-col>
        <b-col sm="10">
          <b-row>
            <b-col sm="4" class="my-2">
              <b-form-input v-model="passwordCount" size="sm" placeholder="Enter password count"></b-form-input>
            </b-col>
            <b-col sm="4" class="my-2">
              <b-button @click="getAmount()" size="sm" variant="success">Check Price</b-button>
            </b-col>
            <b-col sm="4" class="my-2">
              <div v-if="passwordPrice" class="d-flex justify-content-end align-items-center fw-bold">
                <p>{{ passwordPrice }} UZS</p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="my-1">
        <b-col sm="2" class="my-2">
          <label for="input-small">Card Number:</label>
        </b-col>
        <b-col sm="4" class="my-2">
          <b-form-input v-model="cardNumber" v-mask="'#### #### #### ####'" size="sm"
                        placeholder="Enter card number"></b-form-input>
        </b-col>
      </b-row>


      <b-row class="my-1">
        <b-col sm="2" class="my-2">
          <label for="input-small">Expiry:</label>
        </b-col>
        <b-col sm="10">
          <b-row>
            <b-col sm="4" class="my-2">
              <b-form-input v-model="cardExpire" v-mask="'##/##'" size="sm"
                            placeholder="Enter expire date"></b-form-input>
            </b-col>
            <b-col sm="4" class="my-2">
              <b-button v-if="cardNumber && cardExpire" @click="performPayment()" size="sm" variant="success">Send</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="transactionId" class="my-1">
        <b-col sm="2" class="my-2">
          <label for="input-small">Phone Number:</label>
        </b-col>
        <b-col sm="4" class="my-2">
          <b-form-input v-model="phoneNumber" v-mask="'############'" size="sm" placeholder="Enter Phone number"></b-form-input>
        </b-col>
      </b-row>

      <b-row v-if="transactionId" class="my-1">
        <b-col sm="2" class="my-2">
          <label for="input-small">OTP:</label>
        </b-col>
        <b-col sm="4" class="my-2">
          <b-form-input v-model="cardOtp" size="sm" placeholder="Enter confirm code"></b-form-input>
        </b-col>
      </b-row>

      <b-button v-if="transactionId" @click="confirmPayment()" size="sm" variant="success"> Perform payment</b-button>


      <b-row class="mt-3">

        <b-col cols="4" v-if="qrLink">
          <qr-code :text="qrLink" :size="150"></qr-code>
        </b-col>
        <b-col v-if="fiscalSign" cols="4">
          <div class="d-flex">
            <p class="fw-bold me-2">ВФМ: </p> <p> {{ fiscalSign.TerminalID }}</p>
          </div>
          <div class="d-flex">
            <p class="fw-bold me-2">Номер чека: </p> <p> {{ fiscalSign.ReceiptId }}</p>
          </div>
          <div class="d-flex">
            <p class="fw-bold me-2">Фискальный признак: </p> <p>{{ fiscalSign.FiscalSign }}</p>
          </div>
        </b-col>

        <b-col cols="12" v-if="passwords" class="mt-3">
          <b-form-textarea
              v-model="passwords"
              rows="6"
              max-rows="8"
          ></b-form-textarea>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="d-flex align-items-center">
            <p class="me-3">Powered by</p>
            <img src="../src/assets/img/logoQuality.svg" alt="logo-myuzcard" style="fill: #1F3E79">
          </div>
        </b-col>
      </b-row>


    </div>
  </div>
</template>

<style>

</style>
<script>


import MyHeader from "@/components/MyHeader";

export default {
  components: {MyHeader},
  data() {
    return {
      passwordCount: null,
      passwordPrice: null,
      cardNumber: null,
      cardExpire: null,
      cardOtp: null,
      phoneNumber: null,
      transactionId: null,
      passwords: '',
      qrLink: null,
      fiscalSign: null,
      loading: false
    }
  },
  computed: {},
  methods: {
    async getAmount() {
      try {
        this.loading = true;
        const res = await this.$http.get('/api/Password/getAmount', {params: {passwordsCount: this.passwordCount}});
        this.passwordPrice = res.result;
      } catch (e) {
        console.log(e.response.data.message)
      } finally {
        this.loading = false;
      }
    },
    async performPayment() {
      try {
        this.loading = true;
        let cardNumber = this.cardNumber.replace(/\s/g, '');
        const [month, year] = [this.cardExpire.substring(0, 2), this.cardExpire.substring(3, 5)];
        const res = await this.$http.post('/api/Password/performPayment',
            {
              "cardNumber": cardNumber,
              "expireDate": `${year}${month}`,
              "passwordsCount": this.passwordCount
            });
        this.transactionId = res.result;
      } catch (e) {
        console.log(e.response.data.message)
      } finally {
        this.loading = false;
      }
    },
    async confirmPayment() {
      try {
        this.loading = true;
        const res = await this.$http.post('/api/Password/confirmPayment', {
          "transactionId": this.transactionId,
          "otp": this.cardOtp,
          "passwordsCount": this.passwordCount,
          "phoneNumber": this.phoneNumber
        });
        if  (res.result.passwords) {
          for (let i = 0; i < res.result.passwords.length; i++) {
            this.passwords += res.result.passwords[i] + '\n'
          }
        }
        this.qrLink = res.result.fiscalSign.QRCodeURL;
        this.fiscalSign = res.result.fiscalSign;
      } catch (e) {
        console.log(e.response.data.message)
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
  }
}
</script>