<template>
  <div>
    <div class="container">
      <b-navbar toggleable="lg">
        <b-navbar-brand href="#">
          <img src="../assets/img/logo-futtech.png" class="my-logo" alt="Kitten">
        </b-navbar-brand>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyHeader"
}
</script>

<style scoped>

</style>