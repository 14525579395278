import axios from 'axios'


export const baseURL = 'https://futtech.uz/';



export const http = axios.create({
    baseURL: baseURL
});


function setConfiguration(provider) {
    provider.interceptors.request.use(config => {
            config.headers['In'] = 'header';
            config.headers['Accept'] = 'application/json';
            config.headers['Access-Control-Allow-Origin'] = '*';
            config.headers['Content-Type'] = 'application/json';
            return config;
        },
        error => Promise.reject(error)
    );
    provider.interceptors.response.use(res => res.data,
        error => {
            if (error.response && (error.response.status === 401)) {
                console.log('401')
            } else if (error.response && (error.response.status === 403)) {
                console.log('403')
            }
            return Promise.reject(error)
        });
}

setConfiguration(http)


export default http