import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import http from "@/plugins/axios";
import { BootstrapVue } from 'bootstrap-vue';
import VueQRCodeComponent from 'vue-qr-generator'
import { VueMaskDirective } from 'v-mask'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/main.css';
import './assets/css/responsive.css';



Vue.prototype.$http = http;
Vue.config.productionTip = false;



Vue.use(BootstrapVue)


Vue.component('qr-code', VueQRCodeComponent)


Vue.directive('mask', VueMaskDirective);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
